var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
import UsersAdminPanelTableRow from './UsersAdminPanelTableRow.vue';
import UsersAdminPanelFormEdit from './UsersAdminPanelFormEdit.vue';
import { json } from 'overmind';
import EmptyMessage from '@/components/EmptyMessage.vue';
let UsersAdminPanelTable = class UsersAdminPanelTable extends Vue {
    constructor() {
        super(...arguments);
        this.openFormEdit = false;
        this.userEditId = '';
    }
    get filteredListUser() {
        // @ts-ignore TS2349: property inexistent
        return json(this.users).filter((item) => {
            return item.attributes.name
                .toLowerCase()
                .includes(this.searchTermUser.toLowerCase());
        });
    }
    showModalRemoveUser(userId, name) {
        this.$emit('showModalRemoveUser', userId, name);
    }
    editUserForm(userId, nameUserEdit, emailUserEdit, typeUser) {
        this.openFormEdit = true;
        this.userEditId = userId;
        this.$emit('editUserForm', userId, nameUserEdit, emailUserEdit, typeUser);
    }
    userFormEdit(data) {
        this.$emit('userFormEditConfirm', data);
    }
};
__decorate([
    Prop({ default: '' })
], UsersAdminPanelTable.prototype, "userRole", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelTable.prototype, "openForm", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelTable.prototype, "searchTermUser", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelTable.prototype, "currentUserEmail", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersAdminPanelTable.prototype, "users", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelTable.prototype, "showHeaderTable", void 0);
UsersAdminPanelTable = __decorate([
    Component({
        name: 'UsersAdminPanelTable',
        components: {
            UsersAdminPanelTableRow,
            UsersAdminPanelFormEdit,
            EmptyMessage,
        },
    })
], UsersAdminPanelTable);
export default UsersAdminPanelTable;
