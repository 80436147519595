var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop } from 'vue-property-decorator';
let UsersAdminPanelTableRow = class UsersAdminPanelTableRow extends Vue {
    getUserRoleRow(role) {
        switch (role) {
            case 'admin':
                return 'Admin';
            case 'owner':
                return 'Proprietário';
            case 'manager':
                return 'Gerente';
            case 'coordinator':
                return 'Coordenador';
            default:
                return 'Prospector';
        }
    }
    handleIconOrName(context) {
        if (this.user.attributes.name !== null) {
            if (context === 'icon') {
                return this.user.attributes.name.substr(0, 2).toUpperCase();
            }
            else {
                const nameCapitalized = this.user.attributes.name.charAt(0).toUpperCase() +
                    this.user.attributes.name.slice(1);
                return nameCapitalized;
            }
        }
    }
    showModalDelete(userId, name) {
        this.$emit('showModalDelete', userId, name);
    }
    openFormEditUser(userId, nameUserEdit, emailUserEdit, typeUser) {
        this.openForm = false;
        this.$emit('showFormEdit', userId, nameUserEdit, emailUserEdit, typeUser);
    }
    closeFormEdit() {
        this.openForm = false;
        this.showHeaderTable = true;
    }
};
__decorate([
    Prop({ default: false })
], UsersAdminPanelTableRow.prototype, "showHeaderTable", void 0);
__decorate([
    Prop({ default: () => { } })
], UsersAdminPanelTableRow.prototype, "user", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelTableRow.prototype, "currentUserEmail", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelTableRow.prototype, "openForm", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelTableRow.prototype, "userRole", void 0);
UsersAdminPanelTableRow = __decorate([
    Component({
        name: 'UsersAdminPanelTableRow',
    })
], UsersAdminPanelTableRow);
export default UsersAdminPanelTableRow;
