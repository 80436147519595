var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import UsersAdminPanelSkeletonTable from '../components/UsersAdminPanelSkeletonTable.vue';
import UsersAdminPanelHeader from '../components/UsersAdminPanelHeader.vue';
import UsersAdminPanelTable from '../components/UsersAdminPanelTable.vue';
import UsersAdminPanelForm from '../components/UsersAdminPanelForm.vue';
import { Component, Watch } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import { connect, overmind } from '@/overmind';
import { isEmpty } from 'lodash';
import { json } from 'overmind';
import Vue from 'vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { copyToClipboard } from 'quasar';
let UserAdminPanelContainer = class UserAdminPanelContainer extends Vue {
    constructor() {
        super(...arguments);
        this.title = 'Gerenciar usuários';
        this.loading = false;
        this.dialogConfirmUpdateUser = false;
        this.userToRemoveId = '';
        this.userToRemoveName = '';
        this.loadingDeleteUser = false;
        this.userEditedSeccesfully = false;
        this.userCreatedSeccesfully = false;
        this.searchUsers = '';
        this.openForm = false;
        this.showHeaderTable = true;
        this.keyAdminPanelTable = 0;
        this.plan_name = localStorage.getItem('user_plan_name');
        this.showAllowedLimitErrorMessage = false;
        this.sharableAccessToken = '';
    }
    async mounted() {
        this.$gtag.pageview({ page_path: this.$route.path });
        mixpanelTracking('painel do usuário: Abriu a aba Gerenciamento de usuários');
    }
    async searchTermUser(search) {
        this.searchUsers = search;
    }
    async confirmFormData(data, actualRole, password) {
        const user = {
            ...data,
            password_confirmation: password,
            role: actualRole,
        };
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.createUsers({ user });
        if (result.status === 201) {
            this.loading = true;
            this.openForm = false;
            this.userCreatedSeccesfully = false;
            // @ts-ignore TS2349: property inexistent
            await this.actions.getSubUsers();
        }
        this.searchUsers = '';
        this.loading = false;
    }
    async confirmEditUser(data) {
        // @ts-ignore TS2349: property inexistent
        const result = await this.actions.updateUser({ ...data });
        if (result.status === 200) {
            this.loading = true;
            this.openForm = false;
            this.userEditedSeccesfully = false;
            // @ts-ignore TS2349: property inexistent
            await this.actions.getSubUsers();
        }
        this.searchUsers = '';
        this.loading = false;
    }
    async deletedUser() {
        const userId = this.userToRemoveId;
        // @ts-ignore TS2349: property inexistent
        const index = json(this.users).findIndex((item) => item.id === userId);
        this.loadingDeleteUser = true;
        // @ts-ignore TS2349: property inexistent
        await this.actions.removeUser({ userId });
        // this.loading = true;
        this.loadingDeleteUser = false;
        this.dialogConfirmUpdateUser = false;
        this.loading = false;
        if (index !== -1) {
            // @ts-ignore TS2349: property inexistent
            this.actions.removeSubUsers(index);
            this.keyAdminPanelTable += 1;
        }
        this.searchUsers = '';
    }
    openFormAddUser() {
        this.openForm = !this.openForm;
        this.showHeaderTable = !this.showHeaderTable;
    }
    get currentUserRole() {
        if (
        // @ts-ignore
        isEmpty(this.currentUser) ||
            // @ts-ignore
            isEmpty(this.currentUser.role)) {
            // @ts-ignore
            return this.$auth.user().role;
        }
        else {
            // @ts-ignore
            return this.currentUser.role;
        }
    }
    get userDomainEmail() {
        // @ts-ignore
        if (isEmpty(this.currentUser)) {
            // @ts-ignore
            return this.$auth.user().email.match(/(?<=@)\w+/)[0];
        }
        else {
            // @ts-ignore
            return this.currentUser.email.match(/(?<=@)\w+/)[0];
        }
    }
    //Hiden modal to remove user
    hideModal() {
        this.dialogConfirmUpdateUser = false;
    }
    // Show modal to remove user
    showModalRemove(userId, name) {
        this.dialogConfirmUpdateUser = true;
        this.userToRemoveId = userId;
        this.userToRemoveName = name;
    }
    closeFormAfterEditUser() {
        this.openForm = false;
        this.showHeaderTable = true;
    }
    closeFormAfterCreateUser() {
        this.openForm = !this.openForm;
        this.showHeaderTable = !this.showHeaderTable;
    }
    handleMaxUsersCount() {
        const plan = overmind.state.users.planInformation;
        return plan && plan.maxUsersCount ? plan.maxUsersCount : 0;
    }
    loadingUsersList() {
        // @ts-ignore TS2349: property inexistent
        return this.listUsersStatus === 'fetching';
    }
    canAddUser() {
        return (
        // @ts-ignore TS2349: property inexistent
        this.planInformation &&
            // @ts-ignore TS2349: property inexistent
            this.handleMaxUsersCount() > this.users.length);
    }
    async refreshAccessToken() {
        // @ts-ignore
        const result = await this.actions.getUserMe();
        setTimeout(() => {
            // @ts-ignore
            this.sharableAccessToken = this.currentUser.sharableAccessToken;
            copyToClipboard(this.sharableAccessToken)
                .then(() => { })
                .catch(e => { });
        }, 100);
    }
};
__decorate([
    Watch('userEditedSeccesfully')
], UserAdminPanelContainer.prototype, "closeFormAfterEditUser", null);
__decorate([
    Watch('userCreatedSeccesfully')
], UserAdminPanelContainer.prototype, "closeFormAfterCreateUser", null);
UserAdminPanelContainer = __decorate([
    Component(connect(({ state, effects, actions }) => ({
        users: state.users.users,
        adminState: state.admin,
        actions: actions.users,
        adminActions: actions.admin,
        effects: effects.users,
        planInformation: state.users.planInformation,
        currentUser: state.users.currentUser,
        currentUserEmail: state.users.currentUserEmail,
        listUsersStatus: state.users.listUsersStatus,
    }), {
        components: {
            Modal,
            UsersAdminPanelHeader,
            UsersAdminPanelSkeletonTable,
            UsersAdminPanelForm,
            UsersAdminPanelTable,
        },
    }))
], UserAdminPanelContainer);
export default UserAdminPanelContainer;
