var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import UsersAdminPanelForm from './UsersAdminPanelForm.vue';
import { isEmpty } from 'lodash';
let UsersAdminPanelEdit = class UsersAdminPanelEdit extends Vue {
    constructor() {
        super(...arguments);
        this.data = {
            id: '',
            name: '',
            email: '',
            role: '',
        };
        this.options = [
            {
                label: 'Gestor (Faz buscas, cria e compartilha listas)',
                value: 'manager',
            },
            {
                label: 'Coordenador (Faz buscas e cria listas)',
                value: 'coordinator',
            },
            {
                label: 'Prospector (acessa listas compartilhadas)',
                value: 'prospector',
            },
        ];
    }
    onUserChange(curr, old) {
        this.setUserEdit();
    }
    openFormUserEdit() {
        this.openForm = true;
    }
    setUserEdit() {
        this.data = {
            id: this.user.id,
            name: this.user.attributes.name,
            email: this.user.attributes.email,
            role: this.user.attributes.role,
        };
    }
    confirmFormEditUser() {
        if (isEmpty(this.data.email) ||
            isEmpty(this.data.name) ||
            isEmpty(this.data.role)) {
            this.$q.notify({
                message: 'Preencha todos os campos',
                type: 'warning',
            });
        }
        else {
            this.$emit('confirmFormEditUser', this.data);
        }
    }
    closeFormEdit() {
        this.data = {
            id: '',
            name: '',
            email: '',
            role: 'Escolha uma opção',
        };
        this.$emit('closeFormeEdit');
    }
};
__decorate([
    Prop({ default: false })
], UsersAdminPanelEdit.prototype, "showHeaderTable", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelEdit.prototype, "openFormEdit", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelEdit.prototype, "openForm", void 0);
__decorate([
    Prop({ default: () => [] })
], UsersAdminPanelEdit.prototype, "users", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelEdit.prototype, "userRole", void 0);
__decorate([
    Prop({ default: () => { } })
], UsersAdminPanelEdit.prototype, "user", void 0);
__decorate([
    Watch('user', { immediate: true })
], UsersAdminPanelEdit.prototype, "onUserChange", null);
UsersAdminPanelEdit = __decorate([
    Component({
        name: 'UsersAdminPanelTable',
        components: {
            UsersAdminPanelForm,
        },
    })
], UsersAdminPanelEdit);
export default UsersAdminPanelEdit;
