var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"tbodyDataFromUser--AdminUser",attrs:{"data-cy":"user-admin-table-list-users-row"}},[_c('td',{staticClass:"name-cell-from-user tdNameFromUser border-left-table--user",staticStyle:{"width":"auto"}},[_c('q-avatar',{attrs:{"color":"indigo-3 titleAvatarUser","text-color":"white"}},[_vm._v(_vm._s(_vm.handleIconOrName('icon')))]),_vm._v("\n    "+_vm._s(_vm.handleIconOrName('name'))+"\n  ")],1),_c('td',{staticClass:"colorTd"},[_vm._v("\n    "+_vm._s(_vm.user.attributes.email)+"\n  ")]),_c('td',{staticClass:"colorTd"},[_vm._v("\n    "+_vm._s(_vm.getUserRoleRow(_vm.user.attributes.role))+"\n  ")]),_c('td',{staticClass:"border-right-table--user colorTd"},[(
        _vm.userRole === 'admin' || _vm.userRole === 'owner' || _vm.userRole === 'manager'
      )?_c('div',[(
          _vm.user.attributes.role !== 'admin' &&
          _vm.user.attributes.role !== 'owner' &&
          _vm.user.attributes.email !== _vm.currentUserEmail
        )?_c('q-btn',{attrs:{"flat":"","round":"","data-cy":"user-admin-table-row-menu-btn","icon":"mdi-dots-vertical"}},[_c('q-menu',{attrs:{"data-cy":"user-admin-table-row-menu"}},[_c('div',{staticClass:"q-pa-xs"},[_c('q-list',{attrs:{"data-cy":"user-admin-table-row-list"}},[_c('q-item',{attrs:{"data-cy":"user-admin-table-row-menu-list-edit-button","clickable":""},on:{"click":function($event){return _vm.openFormEditUser(
                    _vm.user.id,
                    _vm.user.attributes.name,
                    _vm.user.attributes.email,
                    _vm.user.attributes.role
                  )}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"size":"20px","color":"grey-10","name":"icon-edit"}})],1),_c('q-item-section',[_vm._v("Editar usuário")])],1),_c('q-item',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":"","data-cy":"user-admin-table-row-menu-list-delete-button"},on:{"click":function($event){return _vm.showModalDelete(_vm.user.id, _vm.user.attributes.name)}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"size":"20px","color":"grey-7","name":"icon-trash"}})],1),_c('q-item-section',{staticStyle:{"margin-left":"-15px"}},[_vm._v("\n                  Deletar usuário\n                ")])],1)],1)],1)])],1):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }