var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
let UsersAdminPanelForm = class UsersAdminPanelForm extends Vue {
    constructor() {
        super(...arguments);
        this.option = 'Escolha uma opção';
        this.loadingFormCreate = false;
        this.coordinator = '';
        this.prospect = '';
        this.manager = '';
        this.user = {
            name: '',
            email: '',
            role: '',
            password: '',
            password_confirmation: '',
        };
        this.typesUsers = {
            typeUser: [
                (this.manager = 'Gestor (Faz buscas, cria e compartilha listas)'),
                (this.coordinator = 'Coordenador (Faz buscas e cria listas)'),
                (this.prospect = 'Prospector (acessa listas compartilhadas)'),
            ],
        };
    }
    async confirmFormData() {
        this.loadingFormCreate = true;
        const validatedForm = await this.validFormData();
        const userType = this.verificationTypeUser(this.option);
        if (validatedForm) {
            this.handleUserName();
            this.$emit('confirmFormData', this.user, userType, this.user.password);
        }
        else {
            this.$q.notify({
                message: 'Todos os campos sao obrigatórios, preencha-os devidamente!',
                type: 'warning',
            });
        }
        this.loadingFormCreate = false;
    }
    verificationTypeUser(role) {
        if (role === this.typesUsers.typeUser[0]) {
            return 'manager';
        }
        else if (role === this.typesUsers.typeUser[1]) {
            return 'coordinator';
        }
        else if (role === this.typesUsers.typeUser[2]) {
            return 'prospect';
        }
    }
    handleUserName() {
        this.user = { ...this.user, name: this.user.name.trim() };
    }
    validFormData() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2349: property inexistent
        return this.$refs.formSubUser.validate().then(success => success);
    }
    cancelForm() {
        this.user = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            role: '',
        };
        this.option = 'Escolha uma opção';
        this.$emit('cancelForm');
    }
};
__decorate([
    Prop({ default: false })
], UsersAdminPanelForm.prototype, "showHeadersTable", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelForm.prototype, "subDomainEmailUser", void 0);
__decorate([
    Prop({ default: false })
], UsersAdminPanelForm.prototype, "openForm", void 0);
__decorate([
    Prop({ default: '' })
], UsersAdminPanelForm.prototype, "userRole", void 0);
UsersAdminPanelForm = __decorate([
    Component({
        name: 'UsersAdminPanelForm',
    })
], UsersAdminPanelForm);
export default UsersAdminPanelForm;
